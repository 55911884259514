import React from 'react'
import { Link } from 'gatsby'
import ReactWOW from 'react-wow'


const Referents = () => {
    return (
        <div className="it-services-banner">
            <div className="container">
                <div className="row align-items-center ptb-30">
                    <div className="col-lg-12 col-md-12 pt-30 pb-70 center">
                        <h2 className="subtitle">Wispeek : Mon espace référent</h2>
                    </div>

                        <div className="col-lg-6 col-md-6">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box section-title pt-50">
                                    <p className='pb-30'>Référent, superviseur, accédez au centre de pilotage sécurisé.</p>
                                    <Link to="https://gerer.wispeek.com" className="default-btn btn-custom">
                                        <i className="flaticon-right"></i> 
                                        Espace référent <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box section-title pt-50">
                                    <p className='pb-30'>Responsable hiérarchique, vous souhaitez déclarer un signalement.</p>
                                    <Link to="https://gerer.wispeek.com/s/Y5t68pGcpindHtemP" className="default-btn" style={{backgroundColor:'coral'}}>
                                        <i className="flaticon-right"></i> 
                                        Déclarer un signalement <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default Referents;